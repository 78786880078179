import React from 'react'
import './Home.css'
// import { Helmet } from 'react-helmet-async'
const Home = () => {
    return (
        <>
            {/* <Helmet>
        
            <meta name='description' content='This is our Home page'/>
            <link rel='canonical' href='/home'/>
            <meta name='robots' content='noindex'/>
        </Helmet> */}
            <section className='home' id='home'>
                <div className='home-container container'>

                    <div className='home-content'>
                        <h2 className='home-subtitle'
                          style={{
                                borderRadius: '3px',
                                fontWeight: 'bold',
                                textShadow: '1px 1px red',
                            }}
                        >
                            Welcome To EaseSeat
                        </h2>
                        <h3
                            className="home-title"
                            style={{
                                borderRadius: '8px',
                                fontWeight: 'bold',
                                textShadow: '2px 2px red',
                            }}
                        >
                            Your Gateway to a Seamless Salon and Spa Experience
                        </h3>

                        <h2 className='home-description'
                            style={{
                                color: '#DAF7A6',
                                borderRadius: '8px',
                                fontWeight: 'bold',
                                textShadow: '2px 2px black'

                            }}>Discover the Convenience of Booking Salon and Spa Services with EaseSeat </h2>
                        {/* <p className='home-description'>At Ease Seat, we are revolutionizing the way you book and enjoy salon and spa services. Our mission is to provide you with a seamless and transparent booking experience, eliminating long waits and uncertainties. Whether you’re looking to pamper yourself with a luxurious spa day or get a stylish new haircut, Ease Seat makes the process effortless and hassle-free. </p> */}
                        <div className='home-btns'
                          style={{
                                borderRadius: '8px',
                                fontWeight: 'bold',
                                textShadow: '2px 2px blue',
                            }}>
                            <a href='#contact' className='btn home-btn'
                            
                            >
                                Contact-Us
                            </a>

                            <a href='#download' className='btn home-btn'>
                                Download Now
                            </a>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Home